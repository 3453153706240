<!--
 * @Description: 
 * @Author: 琢磨先生
 * @Date: 2022-11-30 10:40:46
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2022-11-30 10:40:47
-->



<template>
  <el-drawer
    :title="title"
    v-model="drawer"
    direction="rtl"
    size="1000px"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    custom-class="custom"
  >
    <el-form :model="form" ref="form" :rules="rules" label-width="140px">
      <el-form-item label="名称" prop="name">
        <el-col :span="10">
          <el-input v-model="form.name" placeholder="请输入名称"></el-input>
        </el-col>
      </el-form-item>
      <el-form-item label="描述" prop="description">
        <el-col :span="20">
          <el-input v-model="form.description" placeholder type="textarea"></el-input>
        </el-col>
      </el-form-item>
    </el-form>

    <el-divider content-position="left">权限策略</el-divider>
    <el-container>
      <div style="width: 100%">
        <el-tabs>
          <el-tab-pane v-for="item in app_powers" :label="item.app_name" :key="item.app_key">
            <el-table
              :ref="(el)=>{ item.ref_table = el;}"
              :data="item.powers"
              border
              row-key="id"
              default-expand-all
              height="100%"
              @selection-change="tableSelectionChange"
            >
              <el-table-column type="selection" width="55" />
              <el-table-column label="名称" prop="name"></el-table-column>
            </el-table>
          </el-tab-pane>
        </el-tabs>
      </div>
    </el-container>
    <template #footer>
      <el-button type="primary" @click="onSubmit" :loading="saving" :disabled="saving">确定</el-button>
      <el-button @click="cancelClick" :disabled="saving">取消</el-button>
    </template>
  </el-drawer>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      saving: false,
      drawer: false,
      title: "新增岗位",
      app_powers: [],
      form: {},
      rules: {
        name: [
          {
            required: true,
            message: "请输入名称",
            trigger: "blur",
          },
        ],
      },
      //选中的数据
      selectedPowers: [],
    };
  },
  props: ["item", "reload"],
  watch: {
    item: {
      handler() {
        if (this.item) {
          this.drawer = true;
          if (this.item.id) {
            this.form = Object.assign({}, this.item);
            this.title = "修改岗位";
            this.loadDtl();
          } else {
            this.title = "新增岗位";
          }
        }
      },
      immediate: true,
    },
  },
  created() {
    this.loadPowers();

  },
  methods: {

    
    /**
     * 加载所有的权限策略
     */
    loadPowers() {
      this.$http.get("seller/v1/post/powers").then((res) => {
        if (res.code == 0) {
          this.app_powers = res.data;
        }
      });
    },
    /**
     * 获取详情
     */
    loadDtl() {
      this.$http.get(`seller/v1/post/dtl?id=${this.form.id}`).then((res) => {
        if (res.code == 0) {
          this.dtl = res.data;
          this.app_powers.forEach((x) => {
            x.powers.forEach((item) => {
              this.defaultCheck(item, x.ref_table);
            });
          });
        }
      });
    },

    /**
     * 修改时默认选中
     */
    defaultCheck(item, table) {
      if (this.dtl.powerIds.indexOf(item.id) > -1) {
        table.toggleRowSelection(item, true);
      }else{
        table.toggleRowSelection(item, false);
      }
      if (item.children.length > 0) {
        item.children.forEach((child) => {
          this.defaultCheck(child, table);
        });
      }
    },

    /**
     * 提交保存
     */
    onSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.selectedPowers) {
            this.form.powerIds = this.selectedPowers.map((x) => x.id);
          }

          this.saving = true;
          this.$http
            .post("/seller/v1/post/edit", this.form)
            .then((res) => {
              this.saving = false;
              if (res.code == 0) {
                this.drawer = false;
                this.$message({
                  type: "success",
                  message: res.msg,
                });
                if (this.reload) {
                  this.reload();
                }
              }
            })
            .cath(() => {
              this.saving = false;
            });
        }
      });
    },
    /**
     * 取消
     */
    cancelClick() {
      this.drawer = false;
      if (this.reload) {
        this.reload();
      }
    },

    /**
     * 选择的权限策略数据
     */
    tableSelectionChange() {
      this.selectedPowers = [];
      this.app_powers.forEach((app) => {
        var rows = app.ref_table.getSelectionRows();
        rows.forEach((x) => {
          this.selectedPowers.push(x);
        });
      });
    },
  },
};
</script>

<style>
.custom .el-drawer__body {
  display: flex !important;
  flex-direction: column !important;
}
.custom .el-drawer__body .el-container {
  flex-grow: 1 !important;
  height: 1px;
}
</style>